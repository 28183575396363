const en = {
	// Register
	1: 'Register',
	2: 'Please enter your username',
	3: 'The username must start with a letter, contain only letters, underscores, and numbers, and  be between 5 and 10 in length',
	4: 'Please enter your password',
	5: 'The password can only contain letters and numbers, and must be between 6 and 15 in length',
	6: 'Please enter your password again',
	7: 'Please enter your email',
	8: 'The email address must contain the @ character',
	9: 'The left side of the mailbox @ can be letters, underscores, numbers, dot. and small diagonal bar number- except the initial letter',
	10: 'The right side of the mailbox@must have a dot.',
	11: 'Must start with a letter, an underline, a number between @ and. , and except the initial letter, other can be a letter, an underline, a number, a dot. a small diagonal bar number-',
	12: 'Male',
	13: 'Female',
	14: 'Please enter your invite code',
	15: 'Click here to get invite code',
	16: 'Register',
	17: 'Return to login',
	18: 'Click here to leave a message',
	// Index
	19: 'Dear Crazy Tank players, welcome to login',
	20: 'After logging in, you can view your specific information (including record, teams and assets)',
	21: 'There is a slight delay in the display of personal information on the official website. Please refer to the game data',
	22: 'Sign up',
	23: 'Download',
	24: 'Donate',
	25: 'The Beginners Guide',
	26: 'Game Master Advanced ',
	27: 'Official Forum',
	28: 'Configuration Requirements',
	29: 'Combat Methods',
	30: 'Coming Soon',
	31: 'Item Introduction',
	32: 'Map Introduction',
	33: 'Tank Introduction',
	34: 'News',
	35: 'Notice',
	36: 'Media reviews',
	37: 'Player strategy',
	38: 'Forum hot post',
	39: 'More media reviews',
	40: 'More',
	// Apply
	41: 'Select',
	42: 'Revoke',
	43: 'Approve',
	44: 'Team Name',
	45: 'Leader',
	46: 'Applicant',
	47: 'Level',
	48: 'Create Time',
	49: 'Approved',
	50: 'Rejected',
	51: 'Canceled',
	// Create
	52: 'Create Team(',
	53: ' Coin',
	54: 'Donate Now',
	55: 'Logout',
	56: 'The team name consists of 3-12 characters',
	57: 'Color',
	58: 'Preview',
	59: 'Create',
	60: 'You have joined a team,if you want to create a team, you must quit or disband the original team first',
	61: 'Create team successed',
	// Detail
	62: 'Delete team',
	63: 'Leave team',
	64: 'Operation',
	65: 'Delete',
	66: 'Alias name',
	67: 'Level',
	68: 'Ranking',
	69: 'Score',
	70: 'Win Rate',
	71: 'Last Login',
	72: 'Team member deleted',
	73: 'Are you sure you want to disband the team?',
	74: 'Hint',
	75: 'Confirm',
	76: 'Cancel',
	77: 'Delete team successed',
	78: 'Canceled',
	79: 'Are you sure you want to quit the team?',
	80: 'You have successfully quit the team',
	// Guild Index
	81: 'Select',
	82: 'Join',
	83: 'Name',
	84: 'Team leader',
	85: 'Number of players',
	86: 'Create Time',
	87: 'Team score',
	88: 'Application has been submitted, please wait for the team leader to review!',
	// Donate
	89: 'Donate',
	90: 'How To Donate',
	91: 'Donated Amount',
	92: 'Immediately receive ',
	93: 'Cash',
	94: 'For points sponsorship, please contact',
	95: 'Statement of donation support',
	96: 'Dear friends,',
	97: 'The FortressTank as the last holy land of FortressRed2, we need your support and protection, and we will continue to improve the game is features and fix any problems that may arise.',
	98: 'In order to continue to maintain the operation of the FortressTank, the following expenses are all free sponsorship, hereby declared!',
	99: 'Thank you for your support for the FortressTank in the future!',
	100: 'The Team of FortressTank January 1, 2021',
	101: 'Donation in progress, please be patient and wait for the results…',
	102: 'close',
	103: 'PayPal donation',
	104: 'Alipay scan code donation',
	105: 'QR code donation',
	106: 'The donation has been made',
	// MotifyPassword
	107: 'Please enter your old password',
	108: 'Please enter your password',
	109: 'The password can only contain letters and numbers, and must be between 6 and 15 in length',
	110: 'Please enter your password again',
	111: 'Modify',
	112: 'Please enter your email address',
	113: 'Password modification completed',
	// Login
	114: 'User Login',
	115: 'Log in',
	// SideBar
	116: 'Home Page',
	117: 'Team',
	118: 'Team List',
	119: 'Team Detail',
	120: 'Create Team',
	121: 'Join/Apply',
	122: 'Account',
	123: 'Modify Password',
	124: 'Money',
	125: 'WinCount',
	126: 'LoseCount',
	127: 'Account Status',
	// 
	128: 'Click to download',
	129: 'Note',
	130: 'Must be installed C++ Runtime',
	131: 'Please click Paypal button.',
	132: 'Gift.',
	133: ' Score',
	134: 'Donate for Friend',
	135: 'Friend\'s nickname',
	136: 'Please enter your alias name',
	137: 'nickname must be between 1 and 6 in length.',
	138: 'NickName',
	139: 'Modify NickName',
	140: 'NickName Type',
	141: 'Normal(300 Coin)',
	142: 'Special(1500 Coin)',
	143: 'nickname modification completed'
}

export default {
	...en,
}
