'use strict';
import Vue from 'vue'
import axios from 'axios'
import router from '../router'
import {
	Message
} from 'element-ui'

import store from '../store'

axios.defaults.baseURL = '/';
axios.defaults.timeout = 600000;
axios.defaults.withCredentials = true;
axios.defaults.headers['Content-Type'] = 'application/json';

// 请求拦截
axios.interceptors.request.use(config => {
	config.headers['Authorization'] = store.state.token

	return config;
}, error => {
	return Promise.reject(error);
});

// 响应拦截
axios.interceptors.response.use((response) => {
	if (200 == response.status) {
		return response.data;
	} else if (401 == response.status) {
		console.log('401')

		this.$store.commit("clear")

		router.replace('/Index');

		Message({
			message: err.message,
			type: 'error',
			duration: 3 * 1000
		});
	} else {
		Promise.reject();
	}

	return response.data;
}, (err) => {
	console.log(err);

	return Promise.reject();
});

const http = {
	async get(url, params = null) {
		return await axios.get(url, {
			params
		});
	},
	async post(szAction, szJson) {
		return await axios.post(szAction, szJson)
	},
	getAuth() {
		return axios.defaults.headers.common['Authorization']
	}
};

Object.defineProperty(Vue.prototype, '$http', {
	value: http
});