<template>
	<div class="TopBar">
		<div class="brand">
			<img :src="require('@/assets/Images/Layout/Logo.png')" width="150" height="50">
		</div>

		<el-container>
			<el-header>
				<span style="font-weight:normal;cursor:text;">如果不能进入游戏，请联系

					<el-tooltip placement="bottom-start" effect="light">
						<div slot="content">
							<img :src="require('@/assets/Images/Donate/wechat2.png')" width="300" height="300">
						</div>

						<span>
							<img :src="require('@/assets/Images/Donate/wechat.png')" width="30" height="30"
								style="border-radius: 10px;border: 1px solid #ddd;box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px;">
						</span>
					</el-tooltip>

					或

					<el-tooltip placement="bottom-start" effect="light">
						<div slot="content">
							<img :src="require('@/assets/Images/Donate/KakaoTalk.png')" width="300" height="300">
						</div>

						<span>
							<img :src="require('@/assets/Images/Donate/KakaoTalk2.png')" width="30" height="30"
								style="border-radius: 10px;border: 1px solid #ddd;box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px;">
						</span>
					</el-tooltip>

					或

					<a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=2653802161&site=qq&menu=yes"><img
							border="0" src="http://wpa.qq.com/pa?p=2:2653802161:51" alt="点击这里给我发消息"
							title="点击这里给我发消息" /></a>

					或

					<a target="_blank"
						href="https://qm.qq.com/cgi-bin/qm/qr?k=DcERa6_MpOcD-0FVwijPqL_gmGK_GNMB&jump_from=webapi"><img
							border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="爱上坦克" title="爱上坦克"></a>
				</span>
			</el-header>
		</el-container>

		<div class="User" v-if="null != getUserInfo">
			<el-dropdown @command="handleCommand">
				<span class="el-dropdown-link">
					{{ getUserInfo.userId }}
					<i class="el-icon-arrow-down el-icon--right"></i>
				</span>

				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="a">{{$t('89')}}</el-dropdown-item>
					<el-dropdown-item command="b">{{$t('55')}}</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TopBar',
		data() {
			return {
				userInfo: null
			}
		},
		async mounted() {
			if (null == this.$store.state.UserInfo) {
				this.$store.state.ShowLoginPanel = true;

				this.$router.replace('/Index');
			}
		},
		computed: {
			getUserInfo() {
				this.userInfo = this.$store.state.UserInfo

				return this.$store.state.UserInfo;
			}
		},
		methods: {
			handleCommand(command) {
				if (command === 'a') {
					this.$router.push('/Home/User/Donate');
				} else if (command === 'b') {
					this.logout();
				}
			},
			async logout() {
				this.$store.commit('setToken', null)

				this.$store.commit('setUserInfo', null)

				this.$router.replace('/Index');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.TopBar {
		$top: 60px;
		$left: 200px;

		position: fixed;
		left: 0;
		top: 0;
		z-index: 101;
		height: $top;
		width: 100%;
		background: #fff3fd;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		// padding-right: 80px;

		.brand {
			display: flex;
			align-items: center;
			width: $left;
			padding-left: 20px;
			box-sizing: border-box;
			// background: #393D49;
		}

		.User {
			height: $top;
			display: flex;
			align-items: center;


			.el-dropdown {
				color: #808080;
				text-align: center;
				cursor: pointer;
				font-size: 20px;
			}
		}
	}

	.el-header,
	.el-footer {
		// background-color: #B3C0D1;
		color: #333;
		text-align: center;
		line-height: 60px;
		border-radius: 10px;
		margin-top: 10px;
		width: 90%;
	}
</style>
