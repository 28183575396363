import Vue from 'vue'
import Router from 'vue-router'
import Layout from './components/Layout/Index'

Vue.use(Router);

export default new Router({
	// mode: 'history',
	routes: [{
			path: '*',
			name: '/',
			component: () => import('./views/Error')
		},
		{
			path: '/',
			name: 'Index',
			component: () => import('./views/Index')
		},
		{
			path: '/Index',
			name: 'Index',
			component: () => import('./views/Index')
		},
		{
			path: '/m',
			name: 'Mobile',
			component: () => import('./views/Mobile/Index')
		},
		{
			path: '/Fortress/MemberDonate/:id',
			name: 'MemberDonate',
			component: () => import('./views/Fortress/MemberDonate')
		},
		{
			path: '/Home',
			component: Layout,
			children: [{
					path: '',
					name: '首页',
					component: () => import('./views/Home/Index')
				},
				{
					path: '/Home/Guild/Index',
					name: '战队列表',
					component: () => import('./views/Home/Guild/Index'),
				},
				{
					path: '/Home/Guild/Detail',
					name: '战队详情',
					component: () => import('./views/Home/Guild/Detail')
				},
				{
					path: '/Home/Guild/Create',
					name: '创建战队',
					component: () => import('./views/Home/Guild/Create')
				},
				{
					path: '/Home/Guild/Apply',
					name: '加入申请',
					component: () => import('./views/Home/Guild/Apply')
				},
				{
					path: '/Home/User/Index',
					name: '账号信息',
					component: () => import('./views/Home/User/Index')
				},
				{
					path: '/Home/User/MotifyPassword',
					name: '修改密码',
					component: () => import('./views/Home/User/MotifyPassword')
				},
				{
					path: '/Home/User/MotifyAliasName',
					name: '修改昵称',
					component: () => import('./views/Home/User/MotifyAliasName')
				},
				{
					path: '/Home/User/Donate',
					name: '捐款',
					component: () => import('./views/Home/User/Donate')
				},
			]
		},
	]
})

const VueRouterPush = Router.prototype.push

Router.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}
