const ko = {
	// Register
	1: '사용자 로그인',
	2: '사용자 이름을 입력하세요',
	3: '사용자 이름은 무조건 알파벳으로 시작해야 되고 영문,숫자,언더라인으로 조합하여 5-10자리로 만들어야 됩니다.',
	4: '비밀번호을 입력하세요.',
	5: '비밀번호는 알파벳,숫자으로 조합되여 6-15자리로 만들어야 됩니다',
	6: '다시 비밀번호를 입력하세요.',
	7: '메일을 입력하세요.',
	8: '메일 주소는 무조건 무자부호를 포함해야 됩니다.',
	9: '메일@의 왼쪽은 머리글자를 제외하여 다른것은 알파벳,언더라인,숫자，콤마(.), 작대기(-)로 채워도 됩니다.',
	10: '메일@의 오른쪽에 무조건 콤마(.)가 있어야 됩니다.',
	11: '골뱅이(@)와 콤마(.)사이에 무조건 알파벳,언더라인,숫자로 시작해야 되고 머리글자를 제외하여 다른것은 알파벳,언더라인,숫자，콤마(.), 작대기(-)로 채워도 됩니다.',
	12: '남',
	13: '여',
	14: '인증번호를 입력하세요',
	15: '여기를 클릭하여 인증번호를 넣어주세요.',
	16: '아이디 생성 완료',
	17: '돌아가기',
	18: '여기를 클릭하고 메세지를 보내주세요',
	// Index
	19: '사랑하는 컨탱거라우스 탱크,동록하세요~',
	20: '등록하면 개인 정보를 확인 가능합니다~(실적,길드 및 재산 포함）',
	21: '공식 사이트에 개인정보는 ‘업데이트 미완료’로 나타나 있으면 게임 데이터를 기준으로 확인하시면 됩니다.',
	22: '회원 가입',
	23: '(게임 다운로드)클릭',
	24: '기부하기',
	25: '초보자 도우미',
	26: '고급 승급',
	27: '커뮤니티',
	28: '티어 등급',
	29: '전투 방법',
	30: '기대해보세요',
	31: '도구 소개',
	32: '지도 소개',
	33: '탱크 소개',
	34: '신문',
	35: '공지사항',
	36: '미디어 평가',
	37: '플레이어 공략',
	38: '인터넷 블로그 이슈 댓글',
	39: '추가적인 미디어 평가',
	40: '다른 것',
	// Apply
	41: '선택',
	42: '취소',
	43: '통과',
	44: '클랜이름',
	45: '클랜장',
	46: '신청자',
	47: '등급',
	48: '클랜 배치시간',
	49: '초대가 승인되었습니다.',
	50: '초대가 거부되었습니다.',
	51: '가입을 취소했습니다.',
	// Create
	52: '클랜을 만드세요( ',
	53: ' 한화충전',
	54: '당장 기부하세요',
	55: '로그아웃',
	56: '클랜이름은 3-12개 무자부호로 구성해야 됩니다',
	57: '컬러',
	58: '미리보기',
	59: '개설',
	60: '클랜 개설 시 기존 클랜 가입이 되어 있으면 탈퇴후 클랜개설이 가능합니다',
	61: '클랜을 성공적으로 개설했습니다',
	// Detail
	62: '클랜을 해제합니다',
	63: '클랜탈퇴했습니다.',
	64: '조작',
	65: '삭제',
	66: '명칭',
	67: '등급',
	68: '순위',
	69: '포인트 적립',
	70: '승률',
	71: '마지막 게임',
	72: '팀원을 삭제했습니다',
	73: '클랜해제 동의합니까?',
	74: '팁',
	75: '확인',
	76: '취소',
	77: '클랜을 성공적으로 해제했습니다',
	78: '취소했습니다',
	79: '클랜탈퇴 동의합니까?',
	80: '클랜탈퇴 성공했습니다',
	// Guild Index
	81: '선택',
	82: '가입',
	83: '명칭',
	84: '팀장',
	85: '팀원인수',
	86: '개설시간',
	87: '클랜 포인트 적립',
	88: '신청제출됐으니 팀장심의를 기다리세요!',
	// Donate
	89: '기부',
	90: '기부방식',
	91: '기부금액',
	92: '한화를 ',
	93: '얻을수 있습니다',
	94: '포인트 적립 찬조 연락처',
	95: '기부 지지 성명',
	96: '존경그러운 여려분：',
	97: 'FortressTank는 FortressRed2에 마지막 성역으로 여러분이 같이 지지하고 지켜야합니다.저희는 게임중에 각종기능들 및 리페어후 잔여된 문제들을 업그레이드할 것입니다.',
	98: 'FortressTank를 운행할수 있도록 유지하기 위하여 아래 비용은 전수 무상으로 드릴거니참고하세요！',
	99: 'FortressTank를 사랑해주셔서 아주 감사합니다!',
	100: 'FortressTank 단체 2021.1.1',
	101: '기부중이니 조금만 기다려주세요~...',
	102: '닫기',
	103: 'Paypal기부',
	104: '알리페이 스캔으로 기부',
	105: 'QR코드로 기부',
	106: '기부 완료했습니다',
	// MotifyPassword
	107: '기존 비밀번호를 입력하세요',
	108: '새로운 비밀번호를 입력하세요',
	109: '비밀번호는 알파벳,숫자로만 6-15자리로 입력 가능합니다.',
	110: '비밀번호 다시 입력하세요',
	111: '변경',
	112: '메일을 입력하세요',
	113: '비밀번호 변경 완료됐습니다',
	// Login
	114: '사용자 등록',
	115: '등록',
	// SideBar
	116: '메인 화면',
	117: '클랜',
	118: '클랜 리스트',
	119: '클랜 상세정보',
	120: '클랜 개설',
	121: '신청 가입',
	122: '계정',
	123: '비밀번호을 입력하세요',
	124: '금액',
	125: '승리횟수',
	126: '패배횟수',
	127: '계정상태',
	// 
	128: '클릭 하여 다운로드 하 다.',
	129: '주의 하 다.',
	130: '반드시 설치 해 야 한다 C++ Runtime',
	131: '상단의 PayPal 버튼을 클릭해주세요.',
	132: '선물.',
	133: '포인트 적립',
	134: '친구를 위해 기부하다.',
	135: '친구의별명',
	136: '닉네임을 입력하십시오.',
	137: '닉네임을 1-6자리로 만들어야 됩니다',
	138: '닉네임을',
	139: '아이디변경',
	140: '닉네임 유형',
	141: '일반 아이디(300 한화충전)',
	142: '특문아이디 (1500 한화충전)',
	143: '닉네임 수정 완료.'
}

export default {
	...ko,
}
