import Vue from 'vue'
import App from './App.vue'
import i18n from '@/locale'
import router from './router'
import ElementUi from 'element-ui'
import './assets/scss/element-variables.scss'
import './utils'
import store from './store'
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false

Vue.use(ElementUi);
Vue.use(VueClipboard);

new Vue({
	i18n,
	render: h => h(App),
	router,
	store
}).$mount('#app')
