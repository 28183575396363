<template>
	<div id="app" :style="`zoom:${width}`">
		<router-view />
	</div>
</template>

<script>
	import Vuex from 'vuex';

	export default {
		name: 'App',
		computed: {
			...Vuex.mapState(['Token'])
		},
		components: {},
		data() {
			return {
				width: 0
			};
		},
		mounted() {
			if (this.Token) {
				this.$http.setAuth(this.Token);
			}

			// const path = this.$route.fullPath;

			// if (!this.Token && path != '/Index' && path != '/Fortress/MemberDonate') {
			// 	this.$router.replace('/Index');
			// } else {
			// 	this.$http.setAuth(this.Token);
			// }

			// 创建cnzz统计js
			// const script = document.createElement('script');
			// script.src = 'https://s96.cnzz.com/z_stat.php?id=1277738947&web_id=1277738947';
			// script.language = 'JavaScript';
			// document.body.appendChild(script);

			// if (this.isMobile()) {
			// 	this.$router.replace('/m');
			// }
		},
		watch: {
			// $route: {
			// 	// handler(to, from) {
			// 	// 	setTimeout(() => {
			// 	// 		//避免首次获取不到window._czc
			// 	// 		if (window._czc) {
			// 	// 			let location = window.location;
			// 	// 			let contentUrl = location.pathname + location.hash;
			// 	// 			let refererUrl = '/';
			// 	// 			// 用于发送某个URL的PV统计请求，
			// 	// 			window._czc.push(['_trackPageview', contentUrl, refererUrl]);
			// 	// 			window._czc.push(['_setAutoPageview', false]);
			// 	// 		}
			// 	// 	}, 300);
			// 	// },
			// 	//immediate: true // 首次进入页面即执行
			// }
		},
		created() {
			// if (false == this.isMobile()) {
			// 	let windowWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
			// 	this.width = windowWidth / 1920;

			// 	const that = this;
			// 	window.addEventListener('resize', function() {
			// 		return (() => {
			// 			let windowWidth = document.documentElement.offsetWidth || document.body
			// 				.offsetWidth;
			// 			that.width = windowWidth / 1920;
			// 		})();
			// 	});
			// }
		},
		methods: {
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)

				return flag;
			}
		}
	};
</script>

<style>
	body {
		margin: 0;
	}

	#app {
		/* font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px; */

		width: 100%;
		/* max-width: 1920px; */
		/* height: calc(100% - 48px); */
	}
</style>
